import React, { useEffect, useState } from "react";
import PageLoading from "../../components/FullPageLoading/PageLoading";
import Footer from "../../components/Footer/Footer";
import ModalSearch from "../../components/Modal/ModalSearch";
import ModalMenu from "../../components/Modal/ModalMenu";
import ModalLogin from "../../components/Modal/ModalLogin";
import Scrollup from "../../components/Scrollup/Scrollup";
import AuctionDetails from "../../components/Auctions/AuctionDetails";
import Header from "../../components/Header/Header";
import PageNotFound from "../../components/PageNotFound/PageNotFoundPage";
import apiClient from "../../helpers/api";
import { HeaderContext } from "../../helpers/headerContext";
import AuctionDetailsSkeleton from "../../Skeleton/Auctions/AuctionDetailsSkeleton";
const ArtDetails = (props) => {

    const auctionCode = typeof props.match.params.code !== 'undefined' ? props.match.params.code : ''
    const [changeClient, setChangeClient] = useState(false);
    const [artId,setArtId] = useState(0)
    const [isLoaded, setIsLoaded]  = useState(false)
    const [isError, setIsError]    = useState(false)
    const changeHeader = () =>{ 
        return <Header/>
    }

    const getDetails = async () => {

        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/art_details/${props.match.params.collection}/${props.match.params.label}`).then(res => {
                setArtId(res.data.art.id)
                setIsLoaded(true)
            }).catch( err => {
                setIsError(true)
            })

        } catch(error){
            setIsError(true)
        }

    }
    
    useEffect(() => {
        changeHeader()
        getDetails()
    }, [changeClient]);

    if (isError) {
        return <PageNotFound url={props.location.pathname}/>
    }

    return (
        <div className="main">
            <PageLoading />
            {changeHeader()} 
            <HeaderContext.Provider value={{ changeClient, setChangeClient}}>
            {
                isLoaded ?   <AuctionDetails auction_code={auctionCode} code={props.match.params.code} art_id={artId} /> : ''
            }
          
            </HeaderContext.Provider>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <ModalLogin />
            <Scrollup />
        </div>
    )
}

export default ArtDetails