import React, { useEffect, useState } from "react";
import apiClient from '../../helpers/api';
import AuctionCollectionSkeleton from "../../Skeleton/Auctions/AuctionCollectionSkeleton";

import { NavLink } from "react-router-dom";
import CountdownTimer from "../CountdownTimer/Countdown";
const AuctionList = () => {
    const [isLoaded,setIsLoaded] = useState(false);
    const [collectionLoaded,setCollectionLoaded] = useState(false);
    const [isError,setIsError] = useState(false);
    const [auctionCollections,setAuctionCollections] = useState([])
    const [isEnded,setisEnded] = useState([]);

    const getCollectionStatus = (v) => {
        if(!isEnded.includes(v)){
            setisEnded(isEnded => [...isEnded,v]);
        }
    }

    const getAuctionCollection = (key_ ='') => {
        setCollectionLoaded(false)
        try{
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_collections/1?key=${key_}`)
            .then( res => {
                if(key_ != '' && key_ != null){
                    setCollectionLoaded(true)
                } else {
                    setIsLoaded(true)
                }
                if(res.data.results.length > 0){
                    setAuctionCollections(res.data.results)
                } else {
                    setAuctionCollections([])
                }
                
            }).catch( err => {
                setIsError(true)
            });
        } catch(error){
           setIsError(true)
        }

    }

    const search = (e) => {
        getAuctionCollection(e.target.value);
    }

    useEffect(() => {
        getAuctionCollection();
    }, [isEnded])

   
    return(
        <section className="item-details-area pt-5">
            <div className='container'>
               {
                isLoaded ?
                <div className="row justify-content-end mb-3">
                        <div className="col-md-4 align-self-end ">
                            <input type="text" id="search_form" onKeyUp={(e) => search(e)} className="form-control mb-2 mr-sm-2" placeholder="Search" />
                        </div>
                </div>
                : ''
               }
               
                
                {
                   
                        isLoaded || collectionLoaded ? 
                        !isError && auctionCollections.length > 0 ? (
                            <>
                            
                            <div className="row">
                                {
                                    auctionCollections.map((val,id) =>{
                                        
                                        return (
                                            <div className="col-md-4 mb-2" key={id}>
                                                <div className="border p-2">
                                                    {
                                                         val.is_started ? 
                                                            isEnded.includes(id) ? <img src={val.list_image} className="img-fluid"></img> : <NavLink to={`/auctions/${val.url}`}><img src={val.list_image} className="img-fluid"></img></NavLink>
                                                         : <img src={val.list_image} className="img-fluid"></img>
                                                    }
                                                
                                                <h3 className="text-dark mb-0 mt-2">{val.title}</h3>
                                                <p className="text-dark mb-2 mt-1 ">{val.description}</p>
                                                <p className="text-dark mb-2 mt-1">{val.scheduled}</p>
                                                {
                                                    val.is_started ? 
                                                    !isEnded.includes(id) ?
                                                    <>
                                                    <CountdownTimer show={val.show_timer != 1 ?  'd-none' : ''} id={id} changeIsEnded={getCollectionStatus} targetDate={val.bid_clode_date} />
                                                    <NavLink to={`/auctions/${val.url}`} className="btn btn-danger rounded-0 px-5 mb-3">BID</NavLink>
                                                    </>
                                                    : <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span></p>
                                                    : <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Coming Soon</span></p>
                                                }

                                               
                                               </div>
                                            </div>
                                        )
                                    })
                                }
                            
                            </div>
                            </> 
                        ) : <div className="alert alert-dark">No Collection Found</div> : (<div className="row"><AuctionCollectionSkeleton collections={3}/></div>)
                    }
                    
               
            </div>
        </section>
    )
}

export default AuctionList;