import { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, } from "react-hook-form";
import { Alert } from "react-bootstrap";

import { HeaderContext } from "../../helpers/headerContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../helpers/api";
import Auth from "../../helpers/auth/Auth";

import CountdownTimer from "../CountdownTimer/Countdown";

const eye = <FontAwesomeIcon icon={faEye} />; 
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;
 
const ModalAuctions = (props) => { 

    const isShow = props.isShow
    const closeModal = props.closeModal
    const source = props.auction_code
    const auction_art_id = props.auction_art_id
    const [email, setEmail] = useState('')
    const { changeClient, setChangeClient } = useContext(HeaderContext);
    const [isLogged, setIsLogged] = useState(false);
    const [showSfOption, setshowSfOption] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [art_details, setArtDetails] = useState({});
    const [bid_amount, setBidAmount] = useState([]);
    const [bids, setBids] = useState([]);
    const [highestBid, setHighestBid] = useState('');
    const [country, setCountry] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({show: false, msg: ""});
    const [user, setUser]   = useState({});
    const [modalType, setModalType] = useState('auction_bids');
    const [showBidLogTime,setShowBidLogTime] = useState(0);
    const [inClickLoading,setIsClickLoading] = useState(false)

    const {register, handleSubmit, setError, formState:{errors}, formState, reset} = useForm({ mode: 'onBlur' })
    const {isSubmitting} = formState;

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const [errorRegistation,setErrorRegstration] = useState('')
    
    const [increamentLabel, setIncreamentLabel] = useState('');

    const [counter, setCounter] = useState(0);
    const [isResending,setIsResending] = useState(false)
    const [isVerifying,setiIVerifying] = useState(false)

    const [verificationCode, setVerificationCode] = useState('')
    const [errorVerification,setErrorVerification] = useState('')
    const [recounter,setRecounter] = useState(false)
    const [isEnded,setisEnded] = useState([]);
    const getCollectionStatus = (v) => {
        if(!isEnded.includes(v)){
            setisEnded(isEnded => [...isEnded,v]);
        }
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const togglePassword = () => {
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password"){
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    // toggle login view
    const onClickRegister = () => {
        setIsLogin(false)
    }
    const onClickLogin = () => {
        setIsLogin(true)
    }

    const getArtDetail = async () => {
        try {
            setIsLoading(true)
            await verifyLogin();
            await apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_art_details/${auction_art_id}`).then(res => {
                    
                setIncreamentLabel(res.data.increament_label)
                    setBidAmount(res.data.amounts);
                    setArtDetails(res.data.art)
                    setBids(res.data.bids)
                    setHighestBid(parseInt(res.data.highest_bid))
                    setShowBidLogTime(res.data.collection.show_bid_log_time)
                    setTimeout(() => {
                       
                        setIsLoading(false)
                        props.setModalType('auction_bids')
                    },300)
                }).catch( err => {
                    setIsLoading(false)
                })
        } catch (error) {
            setIsLoading(false)
        }
    }

    const changeBktModal = (type) => {
        setIsLoading(true)
     
        setTimeout(() => {
            setModalType(type);
            props.setModalType(type)
            setIsLogin(type == 'login' ? true : false)
            setIsLoading(false)
        },300)
    }

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (Auth.isAuthenticated() && res.status == 200) {
               
                setEmail(res.data.email)
                setUser(res.data)
                setIsLogged(true)
                
                setModalType('auction_bids')
               return true;
            } 
        } catch (error) {
           setIsLogged(false)
           setModalType('auction_bids')
           return false;
        }
    }

    //handle submit bid offer
    const onSubmitBid = (data) => {
        try { 
            setIsClickLoading(true)
            if(isLogged){
                if(user.is_verify == 2){

                    apiClient.post(`${process.env.REACT_APP_API}/auctions/process/offer/`, data).then(res => {
                        if (res) {
                            setShowAlert(prevState => ({ ...prevState,
                                show: true,
                                msg: res.data.msg
                            }))
                            
                            getArtDetail()
                            props.getAuctionArts()
                            reset({
                                bid_amount: "",
                            })
    
                            setTimeout(()=>{
                                setShowAlert(prevState => ({ ...prevState,
                                    show: false,
                                    msg: ""
                                }))
                            }, 5000)
                            setIsClickLoading(false)
                        }
                        }).catch(error => {
                        if (error.response.data) {
                            setIsClickLoading(false)
                            const errors = error.response.data.errors
    
                            console.log(errors);
                            if (errors.bidder_name) {
                            
                                setError("bidder_name", { type: "focus", message: errors.bidder_name[0]}, { shouldFocus: true })
                            }
                            if (errors.bid_amount) {
                                getArtDetail();
                                setError("bid_amount", { type: "focus", message: errors.bid_amount[0]}, { shouldFocus: true })
                            }
                        }
                    })

                } else {
                    setIsClickLoading(false)
                    setIsLoading(true)
                    setEmail(user.email)
                    apiClient.post(`${process.env.REACT_APP_API}/auth/resend-email`, {
                        email: user.email,
                        check:1
                    }).then(res => {
                        setIsLoading(false)
                        setModalType('verify')

                    }).catch(error => {
                    })
                }

            } else {
                setIsClickLoading(false)
                setshowSfOption(true)
            }

        } catch (error) {
            console.log(error);
        }

    }
    //handle submit login and register 
    const onSubmitAuth = (data) => {
        if (isLogin) {
            return new Promise(resolve => {
                
                apiClient.post(`${process.env.REACT_APP_API}/auth/login`, data).then(res => {
                    setIsLoading(true)
                    if (res.status == 200) {
                        setEmail(data.email)
                        Auth.storeAuth()
                        setChangeClient(true)
                        setshowSfOption(false)
                        verifyLogin()
                        setTimeout(() => {
                            setIsLoading(false)
                            if(user.is_verify == 2){
                                props.setModalType('auction_bids')
                            } else {
                                props.setModalType('verify')
                            }
                          
                        },1500)

                        setShowAlert(prevState => ({ ...prevState,
                            show: false,
                            msg: ""
                        }))
                      
                    }
                    resolve();
                }).catch(error => {
                    setShowAlert(prevState => ({ ...prevState, show: true,msg: error.response.data.error}))
                    resolve();
                })
            })
        }else{
            return new Promise(resolve => {
               
                apiClient.post(`${process.env.REACT_APP_API}/auth/register`, data).then(res => {
                    setIsLoading(true)
                    if (res.data.code == 200) {
                        setEmail(data.email)
                        Auth.storeAuth()
                        setChangeClient(true)
                        setshowSfOption(false)
                        setModalType('verify')
                        setTimeout(() => {
                            setIsLoading(false)
                             props.setModalType('verify')
                            
                        },1500)

                        setShowAlert(prevState => ({ ...prevState,
                            show: false,
                            msg: ""
                        }))
                        
                    } else {
                        setErrorRegstration(res.data.msg)
                        setIsLoading(false)
                    }
                    resolve();
                }).catch(error => {
                    if (error.response.data) {
                        const errors = error.response.data.errors
                        if (errors.name) {
                            setError("name", { type: "focus", message: errors.name[0]}, { shouldFocus: true })
                        }
                        if (errors.last_name) {
                            setError("last_name", { type: "focus", message: errors.last_name[0]}, { shouldFocus: true })
                        }
                        if (errors.email) {
                            setError("email", { type: "focus", message: errors.email[0]}, { shouldFocus: true })
                        }
                        if (errors.password) {
                            setError("password", { type: "focus", message: errors.password[0]}, { shouldFocus: true })
                        }
                        if (errors.confirm_password) {
                            setError("confirm_password", { type: "focus", message: errors.confirm_password[0]}, { shouldFocus: true })
                        }
                        if (errors.mobile) {
                            setError("mobile", { type: "focus", message: errors.mobile[0]}, { shouldFocus: true })
                        }
                    }
                    resolve();
                })
            })
        }
        
    }

    const onresendEmail = (e) => {
        setIsResending(true)
        let email_ = e.target.getAttribute('data-email');
        try {
            apiClient.post(`${process.env.REACT_APP_API}/auth/resend-email`, {
                email: email_,
            }).then(res => {
                setRecounter(true)
                setIsResending(false)
                setCounter(60)
                countersec()
            }).catch(error => {
                setIsResending(false)
            })
        } catch(error) {
            setIsResending(false)
        }

        e.preventDefault();
        
    }

    const onVerifyCode = (e) => {
        setErrorVerification('')
        setiIVerifying(true)
        try {
            apiClient.post(`${process.env.REACT_APP_API}/auth/vefication-email`, {
                verificationCode: verificationCode,
                email:email
            }).then(res => {
                verifyLogin()
                setModalType('auction_bids')
            }).catch(error => {
                setErrorVerification('invalid code')
                setiIVerifying(false)
            })

        } catch(error) {

        }

    }

    const countersec = () => {
        //setRecounter(false)
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
     }

    useEffect(() => {
        if(!recounter){
            apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`).then(res => {
                setCountry(res.data.data)
             })
            getArtDetail()
        }
        countersec()
    }, [counter])
   
    if (modalType === "login" || modalType === "register") {
        
        return (
            <Modal size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            show={isShow}>
                <Modal.Header className="pb-0">
                    <p className="font-weight-bold pt-3">{isLogin ? 'Login' : 'Register'}</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
                </Modal.Header>
                {!isLoading ? (
                    <Modal.Body className="pt-0">
                        <form onSubmit={handleSubmit(onSubmitAuth)}>
                            {
                                isLogin ? (
                                    <div>
                                        <Alert show={showAlert.show} variant="danger" className="d-flex justify-content-between">
                                            <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                        </Alert>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-0">
                                                    <label className="text-dark font-weight-bold">Email</label>
                                                    <input type="email" className="form-control" {...register("email")} name="email" placeholder="Enter your Email" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="text-dark font-weight-bold">Password</label>
                                                    <input type={passwordShown ? "text" : "password"}  className="form-control" {...register("password")} name="password" placeholder="Enter your Password" required="required" />
                                                </div>
                                                
                                            </div>
                                            <div className="col-12">
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" className="form-check-input checkboxx" id='showPassword' onClick={togglePasswordVisiblity} />
                                                            <label className="form-check-label ml-1 mt-1" for="showPassword">Show Password</label>
                                                        </div>
                                                
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="text-right">
                                                            <a href='/auth/forget_password' target='blank'>Forgot Password</a>
                                                        </div>
                                                    </div>
                                                </div>
                        
                                            </div>
                                    
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" > 
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Login
                                                </button>
                                            </div>
                                        
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickRegister}>Register</button>
                                            </div>
                                            
                                            
                                            <div className="col-12 pt-3">
                                                <p className="text-center text-dark" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegister}>here</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {/* <Alert show={show} variant="danger">
                                            <p>
                                                {errormsg}
                                            </p>
                                        </Alert> */}
                                        <div className="row justify-content-center">
                                        {
                                            !isSubmitting && errorRegistation ? 
                                            <div className="col-12">
                                                <div className="alert alert-danger">{errorRegistation}</div>
                                                </div>
                                            : ''
                                        }
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">First Name</label>
                                                    <input type="text" {...register("name",{required:true})} className={`form-control ${errors && errors.name ? "is-invalid" : ''}`} name="name"  />
                                                    {errors.name && <p className="mt-0 text-danger">{errors.name.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Last Name </label>
                                                    <input type="text" {...register("last_name",{required:true})} className={`form-control ${errors && errors.last_name ? "is-invalid" : ''}`} name="last_name"    />
                                                    {errors.last_name && <p className="mt-0 text-danger">{errors.last_name.message}</p>}
                                                </div>
                                            </div> 
                                        
                                            <div className="col-md-6 col-12 pl-0 d-none">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Username &#40; Optional &#41;</label>
                                                    {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                    {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                    <input type="text" {...register("username")} className="form-control" name="username"  />
                                                    {errors.username && <p className="mt-0 text-danger">{errors.username.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Email</label>
                                                    <input type="email" {...register("email",{required:true})} className={`form-control ${errors && errors.email ? "is-invalid" : ''}`} name="email" />
                                                    {errors.email && <p className="mt-0 text-danger">{errors.email.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Mobile</label>
                                                    <div className="row">
                                                    <div className="col-2 pl-0">
                                                        <select {...register("mobile_code",{required:true})}  className={`form-control ${errors && errors.mobile_code ? "is-invalid" : ''}`} name="mobile_code" >
                                                        <option value="">Code</option>
                                                {
                                                    country && country.map((count_cde,id) => {
                                                        return(<option selected={count_cde.id == 174 ? true : false} value={`+${count_cde.phonecode}`}>{`${count_cde.name} (${count_cde.phonecode})`}</option>)
                                                    })
                                                }
                                                        </select>
                                                    </div>
                                                    <div className="col-10  pl-1 pr-0">
                                                    <input type="text" {...register("mobile",{required:true})} className={`form-control ${errors && errors.mobile ? "is-invalid" : ''}`} name="mobile" />
                                                    </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>  
                                            <input type="hidden" name="isRegistered" {...register("isRegistered")} value={false}/>
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Password</label>
                                                    <input type={passwordType} {...register("password",{required:true})} className={`form-control ${errors && errors.password ? "is-invalid" : ''}`} name="password"  />
                                                    {errors.password && <p className="mt-0 text-danger">{errors.password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                                </div>
                                            </div> 
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Confirm Password</label>
                                                    <input type={confirmpasswordType} {...register("confirm_password",{required:true})} className={`form-control ${errors && errors.confirm_password ? "is-invalid" : ''}`} name="confirm_password"/>
                                                    {errors.confirm_password && <p className="mt-0 text-danger">{errors.confirm_password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                    <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                        { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                    </button>
                                                </div>
                                            </div> 
                                            <div className="col-12">
                                                <div className="form-group mt-1 ml-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" required="required"/>
                                                        <label className="form-check-label ml-1 mt-1" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy"> Terms and Conditions</a></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" >
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Register
                                                </button>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickLogin}>Login</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                        
                    </Modal.Body>) : (
                    <Modal.Body style={{minHeight: '300px'}}>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <div className="spinner-border text-danger" style={{width: '6rem', height: '6rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="pt-3">
                                <p className="font-weight-bold">Loading...</p>
                            </div>
                        </div>
                    </Modal.Body>
                    )
                }
            </Modal>
        );
    } else if(modalType === "verify"){
        return (
            <Modal size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            show={isShow}>
                <Modal.Header className="pb-0">
                    <p className="font-weight-bold pt-3">Verify Email</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
                </Modal.Header>
                <Modal.Body className="pt-0 text-center">
                <p>To place bid please verify your email</p>
                <p>We sent a verification code to {email}<br></br>Check your email and enter the code below</p>
                <p></p>
                <label>Verification Code</label>
                    <input type="text" className="form-control mb-3"   onChange={ e => setVerificationCode(e.target.value) }   />
                    {errorVerification && <p className="text-red m-0 mb-2">{errorVerification}</p>}
                    <button className="btn btn-danger mb-3 btn-block" data-email={email}  onClick={e => onVerifyCode(e)}> 
                    {isVerifying && <span className="spinner-border spinner-border-sm mr-1"></span>} Verify</button>
                    <small>Didn't receive an email? 
                        { counter == 0 ? 
                            <a href="#"  data-email={email} onClick={e => onresendEmail(e)}  disabled={isResending ? true : false} className="ml-1">{isResending && <span className="spinner-border spinner-border-sm mr-1"></span>}Resend</a> 
                            : ''
                        } <small>{counter > 0 && counter}</small></small>
                    
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={isShow}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3">Place BID</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            {
                !isLoading ? (
                    <Modal.Body className="pt-0">
                        <div className="row p-3">
                            <div className="col-md-6">
                                <img src={art_details._merch_image} alt="scarletbox_auction" className="img-fluid"/>
                            </div>
                            <div className="col-md-6">
                                <p className="my-2 font-weight-bolder" style={{fontSize: '20px'}}>{art_details.label}</p>
                                <p className="my-2 font-weight-bold">Artist: {art_details.artist ? art_details.artist.artist_name : ""}</p>
                                {
                                !isEnded.includes(props.key) && props.auctionstatus == 1 ?
                                <CountdownTimer show={props.showTimer != 1 ?  'd-none' : ''} id={props.key} changeIsEnded={getCollectionStatus} targetDate={props.bid_clode_date} />
                                : ""
                                }

                                <form onSubmit={handleSubmit(onSubmitBid)} className="row">
                                    <div className="pl-0 col-10">
                                        <Alert show={showAlert.show} variant="success" className="d-flex justify-content-between">
                                            <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                        </Alert>
                                    </div>
                                    {
                                        user.id ? <input type="hidden"  className="text-dark" {...register("user_id")} value={user.id}/> : ''
                                    }
                                     
                                    <input type="hidden" name="auction_id" {...register("auction_id")} value={props.auction_id}/>
                                    <input type="hidden" name="auction_art_id" {...register("auction_art_id")} value={props.auction_art_id}/>
                                    <input type="hidden" name="source" {...register("source")} value={source}/>

                                    <div className="col-12 pl-0 mb-2">
                                        <p className="mt-0"><span className="font-weight-bold">Starting Price:</span>{} PHP {props.base_price.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</p>
                                    </div>

                                    {
                                        highestBid ? (
                                            <div className="col-12 pl-0 mb-2">
                                                <p className="mt-0"><span className="font-weight-bold">Current Highest Bid:</span> PHP {highestBid.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</p>
                                            </div>
                                        ) : ''
                                    }

                                    <div className="col-12 pl-0 mb-2">
                                        <p className="mt-0"><span className="font-weight-bold">Bid Increment:</span> {increamentLabel}</p>
                                    </div>
                                   
                                   {/*  */}
                                    {
                                        props.auctionstatus == 1  ? 
                                        <>
                                        
                                        <div className="col-10 pl-0">
                                        {
                                           user.id ? 
                                            <div className="form-group mt-0">
                                            <label className="text-dark font-weight-bold">Bidder Name(editable) </label>
                                            <input style={{borderRadius: '0px !important'}} {...register("bidder_name")} defaultValue={user.name + " " + user.last_name} type="text" className="form-control vip bg-light rounded-0" name="bidder_name" required />
                                            {errors.bidder_name && <p className="mt-0 text-danger">{errors.bidder_name.message}</p>}
                                            </div> : ''
                                        }
                                        
                                        </div>
                                        {
                                        !isEnded.includes(props.key) && props.auctionstatus == 1 ?
                                        <>
                                        <div className="col-8 px-0 mb-2">
                                            <div className="form-group mt-0 mb-0">
                                                <select style={{borderRadius: '0px !important'}} {...register("bid_amount")} className="form-control vip bg-light rounded-0">
                                                    {
                                                        bid_amount.map((bid_amount_) => {
                                                            return(<option value={bid_amount_}>{`Php ${bid_amount_.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}`}</option>)
                                                        })
                                                    }
                                                    
                                                </select>
                                                {errors.bid_amount && <p className="mt-0 text-danger">{errors.bid_amount.message}</p>}
                                                {/* <input style={{borderRadius: '0px !important'}} {...register("bid_amount")} type="number" className="form-control vip bg-light rounded-0" name="bid_amount" placeholder="Amount" />
                                                {errors.bid_amount && <p className="mt-0 text-danger">{errors.bid_amount.message}</p>} */}
                                            </div>
                                        </div>
                                        <div className="col-4 px-1">
                                            <button style={{padding: '11px 15px'}} disabled={isSubmitting} className="btn btn-sm btn-danger rounded-0"> {isSubmitting || inClickLoading && <span className="spinner-border spinner-border-sm mr-1"></span>} BID </button>
                                        </div>
                                        <small style={{ fontSize:'10px' }} className="mb-2">Next bid options will appear after highest bid from this set is selected.</small>
                                        {
                                            showSfOption ? 
                                                <small style={{ fontSize:'12px' }} className="col-md-12 p-0">
                                                    To BID, please <a href="#" onClick={() => changeBktModal('register')} >REGISTER</a>, or <a href="#" onClick={() => changeBktModal('login')} >LOGIN</a> to your Scarletbox account.
                                                </small>
                                        : ''
                                        }
                                        
                                        </>
                                        : "" 
                                       }
                                        </> : <><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span><br></br></>
                                    }
                                    
                                    
                                </form>
                            </div>
                        </div>
                        {
                            bids.length != 0 ? (
                                <div className="row table-responsive" style={{maxHeight:"250px"}}>
                                    <table className="table table-sm table-bordered rounded border border-dark">
                                        <thead>
                                            <tr>
                                                <th scope="col">BID Amount</th>
                                                <th scope="col">BIDDER</th>
                                                {
                                                    showBidLogTime === 1 ? <th scope="col">Date</th> : ''
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bids.map((bid, id ) => {
                                                    return (
                                                        <tr key={id}>
                                                            <th scope="row">PHP {parseInt(bid.bid_amount).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</th>
                                                            <td>{bid.bidder_name}</td>
                                                            {
                                                                showBidLogTime === 1 ? <td scope="col">{bid.diff}</td>: ''
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : ""
                        }
                        
                    </Modal.Body>
                ) : (
                    <Modal.Body style={{minHeight: '300px'}}>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <div className="spinner-border text-danger" style={{width: '6rem', height: '6rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="pt-3">
                                <p className="font-weight-bold">Loading...</p>
                            </div>
                        </div>
                    </Modal.Body>
                )
            }
        </Modal>
    );

    
}
 
export default ModalAuctions;